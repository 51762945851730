import useAxiosRepository from '../../../repositories/BaseRepository';
import {
    ApiUserResponse,
    UserSignedAuthUrlResponse,
} from '@/js/auth/types/types';
import { authApiEndpoints } from '../constants/authApiEndpoints';
import { LoginUserPayload } from '@/js/quests/dashboard/types/payloads';

const axiosRepo = useAxiosRepository();

export const fetchUserData = async (
    showError = false,
): Promise<ApiUserResponse> => {
    const api = authApiEndpoints.FETCH_USER_DATA;

    return axiosRepo.post(api, null, { showError });
};

export const fetchAdminUserData = async (): Promise<ApiUserResponse> => {
    const api = authApiEndpoints.FETCH_ADMIN_USER_DATA;

    return axiosRepo.post(api, null);
};

export const userLogin = async (
    payload: LoginUserPayload,
): Promise<ApiUserResponse> => {
    const api = authApiEndpoints.USER_LOGIN;

    return axiosRepo.post(api, payload);
};

export const userLogout = async (
    showError = true,
): Promise<void> => {
    const api = authApiEndpoints.USER_LOGOUT;

    return axiosRepo.post(api, null, { showError });
};

export const getUserSignedAuthUrl = async (intent: string, type: string): Promise<UserSignedAuthUrlResponse> => {
    const api = authApiEndpoints.GET_USER_SIGNED_AUTH_URL;

    return axiosRepo.post(api, { intent, type });
};
