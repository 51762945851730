import {
    AchievementsResponse,
    ActiveUserRound,
    GuildInfo,
    InitialRoundDataResponse,
    ISocialAccount,
    MissionApiResponse,
    Missions,
    MissionsFilters,
    NftCertificatesResponse,
    NFTResponse,
    NFTs,
    Notification,
    NotificationCountDataApiResponse,
    PreviousMissionApiResponse,
    QuestsUserData,
    TangibleRewardResponse,
    UserAccountBalanceResponse,
    UserSocialAccountsResponseData,
    UserStatsResponse,
} from '@/js/quests/dashboard/types/types';
import useAxiosRepository from '@/js/repositories/BaseRepository';
import { ResponseStreakData } from '@/js/quests/dashboard/types/streak';
import { dashboardApiEndpoints } from '@/js/quests/dashboard/constants/dashboardApiEndpoints';
import {
    PasswordResetPayload,
    SendUserPasswordPayload,
    TangibleRewardPayload,
} from '@/js/quests/dashboard/types/payloads';
import { ReportPayload } from '@/js/quests/global/types/report';

const axiosRepo = useAxiosRepository();

export const getUserData = async (showError = true): Promise<QuestsUserData> => {
    const api = dashboardApiEndpoints.GET_USER_DATA;

    return axiosRepo.get(api, { showError });
};

export const getMissionsData = async (
    filters?: MissionsFilters,
    sort = '-published_at',
    page = 1,
    perPage = 10,
    showError = true,
): Promise<Missions> => {
    const api = dashboardApiEndpoints.GET_MISSIONS_DATA(filters, sort, page, perPage);

    return axiosRepo.get(api, { showError });
};

export const getGuildInfo = async (slug: string): Promise<{data: GuildInfo}> => {
    const api = dashboardApiEndpoints.GET_GUILD_INFO(slug);

    return axiosRepo.get(api);
};

export const getMissionData = async (
    guildSlug: string,
    slug: string,
): Promise<MissionApiResponse> => {
    const api = dashboardApiEndpoints.GET_MISSION_DATA(guildSlug, slug);

    return axiosRepo.get(api);
};

export const getMissionPreviewData = async (
    guildSlug: string,
    slug: string,
): Promise<MissionApiResponse> => {
    const api = dashboardApiEndpoints.GET_MISSION_PREVIEW_DATA(guildSlug, slug);

    return axiosRepo.get(api);
};

export const getPreviousMissionData = async (slug: string): Promise<PreviousMissionApiResponse> => {
    const api = dashboardApiEndpoints.GET_PREVIOUS_MISSION_DATA(slug);

    return axiosRepo.get(api);
};

export const getStreakData = async (showError = true): Promise<ResponseStreakData> => {
    const api = dashboardApiEndpoints.GET_STREAK_DATA;

    return axiosRepo.get(api, { showError });
};

export const getActiveUserRound = async (): Promise<ActiveUserRound> => {
    const api = dashboardApiEndpoints.GET_ACTIVE_USER_ROUND;

    return axiosRepo.get(api);
};

export const toggleSoundSetting = async (
    isSoundsEnabled: boolean,
    showError = true,
    showSuccess = true,
): Promise<void> => {
    const api = dashboardApiEndpoints.TOGGLE_SOUND_SETTING;

    const payload = {
        settings: {
            is_sounds_enabled: isSoundsEnabled ? 0 : 1,
        },
    };

    return axiosRepo.patch(api, payload, { showError, showSuccess });
};

export const deactivateUserAccount = async (showError = true, showSuccess = true): Promise<void> => {
    const api = dashboardApiEndpoints.DEACTIVATE_USER_ACCOUNT;

    return axiosRepo.patch(api, null, { showError, showSuccess });
};

export const changeUserPassword = async (
    currentPassword: string,
    newPassword: string,
    confirmPassword: string,
    showError = false,
    showSuccess = true,
): Promise<void> => {
    const api = dashboardApiEndpoints.CHANGE_USER_PASSWORD;

    const payload = {
        current_password: currentPassword,
        new_password: newPassword,
        new_password_confirmation: confirmPassword,
    };

    return axiosRepo.patch(api, payload, { showError, showSuccess });
};

export const addUserPassword = async (
    password: string,
    confirmPassword: string,
    showError = true,
    showSuccess = true,
): Promise<void> => {
    const api = dashboardApiEndpoints.ADD_USER_PASSWORD;

    const payload = {
        password,
        password_confirmation: confirmPassword,
    };

    return axiosRepo.post(api, payload, { showError, showSuccess });
};

export const sendUserEmailVerification = async (
    email: string,
    showError = false,
): Promise<void> => {
    const api = dashboardApiEndpoints.SEND_USER_EMAIL_VERIFICATION;
    const payload = {
        email,
    };

    return axiosRepo.post(api, payload, { showError });
};

export const verifyUserEmailVerificationCode = async (
    code: string,
    showError = false,
    showSuccess = true,
): Promise<void> => {
    const api = dashboardApiEndpoints.VERIFY_USER_EMAIL_VERIFICATION_CODE;
    const payload = {
        code,
    };

    return axiosRepo.post(api, payload, { showError, showSuccess });
};

export const sendUserPasswordReset = async (
    payload: SendUserPasswordPayload,
    showSuccess = true,
): Promise<void> => {
    const api = dashboardApiEndpoints.SEND_USER_PASSWORD_RESET_LINK;

    return axiosRepo.post(api, payload, { showSuccess });
};

export const resetUserPassword = async (
    payload: PasswordResetPayload,
    showSuccess = true,
): Promise<void> => {
    const api = dashboardApiEndpoints.RESET_USER_PASSWORD;

    return axiosRepo.patch(api, payload, { showSuccess });
};

export const feedback = async (
    content: string,
    email: string,
    recaptchaResponse: string,
    showSuccess = true,
): Promise<void> => {
    const api = dashboardApiEndpoints.FEEDBACK;
    const payload = {
        content,
        email,
        recaptchaResponse,
    };

    return axiosRepo.post(api, payload, { showSuccess });
};

export const postReport = async (payload: ReportPayload): Promise<void> => {
    const api = dashboardApiEndpoints.REPORT;

    const requestBody = {
        ...(payload.userProfileId && { user_quest_profile_id: payload.userProfileId }),
        ...(payload.email && { email: payload.email }),
        reason_type: payload.reasonType,
        description: payload.description,
        reportable_type: payload.reportType,
        reportable_identificator: payload.slug,
        recaptchaResponse: payload.recaptchaResponse,
    };

    return axiosRepo.post(api, requestBody);
};

export const getTangibleReward = async (id: string): Promise<TangibleRewardResponse> => {
    const api = dashboardApiEndpoints.GET_TANGIBLE_REWARD(id);

    return axiosRepo.get(api);
};

export const updateTangibleReward = async (id: string, payload: TangibleRewardPayload, showError = true, showSuccess = true): Promise<void> => {
    const api = dashboardApiEndpoints.SET_TANGIBLE_REWARD(id);

    return axiosRepo.patch(api, payload, { showError, showSuccess });
};

export const getUserMissionsData = async (): Promise<Missions> => {
    const api = dashboardApiEndpoints.GET_USER_MISSIONS_DATA;

    return axiosRepo.get(api);
};

export const getUserExamsData = async (): Promise<Missions> => {
    const api = dashboardApiEndpoints.GET_USER_EXAMS_DATA;

    return axiosRepo.get(api);
};

export const getUserAchievementsData = async (): Promise<AchievementsResponse> => {
    const api = dashboardApiEndpoints.GET_USER_ACHIEVEMENTS_DATA;

    return axiosRepo.get(api);
};

export const getUserNftData = async (): Promise<NFTs> => {
    const api = dashboardApiEndpoints.GET_USER_NFT_DATA;

    return axiosRepo.get(api);
};

export const getUserAccountBalance = async (): Promise<UserAccountBalanceResponse> => {
    const api = dashboardApiEndpoints.GET_USER_ACCOUNT_BALANCE;

    return axiosRepo.get(api);
};

export const getUserStatsCount = async (): Promise<UserStatsResponse> => {
    const api = dashboardApiEndpoints.GET_USER_STATS;

    return axiosRepo.get(api);
};

export const mintUserNft = async (
    nftId: string,
): Promise<NFTResponse> => {
    const api = dashboardApiEndpoints.MINT_USER_NFT(nftId);

    return axiosRepo.post(api, null);
};

export const getNftCertificates = async (
    walletAddress: string,
    recaptchaResponse: string,
): Promise<NftCertificatesResponse> => {
    const api = dashboardApiEndpoints.GET_NFT_CERTIFICATES;
    const payload = {
        wallet_address: walletAddress,
        recaptchaResponse,
    };

    return axiosRepo.post(api, payload);
};

export const createInitialUserRound = async (round_uuid: string, isPreview = false): Promise<InitialRoundDataResponse> => {
    const api = dashboardApiEndpoints.CREATE_INITIAL_USER_ROUND;
    const payload = {
        round_uuid,
        is_preview: isPreview,
    };

    return axiosRepo.post(api, payload);
};

export const getToolbarNotifications = async (showError = true): Promise<Notification> => {
    const perPage = 10;
    const api = `user/notifications?filter[display_type]=toolbar&perPage=${perPage}`;

    return axiosRepo.get(api, { showError });
};

export const getToolbarNotificationCount = async (showError = true): Promise<NotificationCountDataApiResponse> => {
    const api = 'user/notifications/count?filter[display_type]=toolbar';

    return axiosRepo.get(api, { showError });
};

export const getMoreToolbarNotifications = async (page: number, showError = true): Promise<Notification> => {
    const perPage = 10;
    const api = `user/notifications?filter[display_type]=toolbar&page=${page}&perPage=${perPage}`;

    return axiosRepo.get(api, { showError });
};

export const updateUserSocialAccount = async (
    socialAccount: ISocialAccount,
    showError = true,
    showSuccess = true,
): Promise<UserSocialAccountsResponseData> => {
    const api = dashboardApiEndpoints.UPDATE_USER_SOCIAL_ACCOUNT;
    const payload = {
        social_network: socialAccount.social_network,
        user_name: socialAccount.user_name,
        is_primary: socialAccount.is_primary,
    };

    return axiosRepo.patch(api, payload, { showError, showSuccess });
};

export const setFireBaseToken = async (token: string): Promise<void> => {
    const api = dashboardApiEndpoints.SET_FIREBASE_DEVICE_TOKEN;
    const payload = {
        device_token: token,
    };

    return axiosRepo.post(api, payload);
};
